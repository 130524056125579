import $ from 'jquery';

$(document).ready(function() {

    /* MATCH HEIGHT EQUAL HEIGHT

    $('.matchHeight').matchHeight();
     */

    /* SWITCHER
    $('.toggle-btn').click(function() {
        $(this).parent().toggleClass('active').siblings().removeClass('active');
        //$(this).parent().find('.single_switcher__active').toggleClass('active').siblings().removeClass('active');
    });
     */

    /* AOS ANIMATION FUNCTION

    $(function() {
        AOS.init();
    });
     */
    var lines = [
        "This is the first line.",
        "This is the second line.",
        "This is the third line."
    ];

    var counter = 0;
    var interval = setInterval(function() {
        $('#typewriter p').eq(counter).text(lines[counter]).addClass('active');
        counter++;

        if (counter === lines.length) {
            clearInterval(interval);
        }
    }, 1000);
});